import React, { useState, useEffect } from 'react';
import { Image } from 'components';
import './styles.scss';

const Cursor = () => {
  // set the starting position of the cursor outside of the screen
  const [hoverAnimation, setHoverAnimation] = useState(false);
  const [gifCursor, setGifCursor] = useState(false);

  useEffect(() => {
    let clientX = -100;
    let clientY = -100;
    let target = {
      closest: () => {},
    };
    const innerCursor = document.querySelector('.cursor');
    document.addEventListener(
      'mousemove',
      (event) => {
        ({ clientX, clientY, target } = event);
      },
      { passive: true }
    );
    const render = () => {
      const cursorWidth = innerCursor.offsetWidth;
      const cursorHeight = innerCursor.offsetHeight;
      innerCursor.style.left = `${clientX - cursorWidth / 2}px`;
      innerCursor.style.top = `${clientY - cursorHeight / 2}px`;

      // cursor types
      const gifElement = target.closest('.with-gif');
      const gifCursorData = gifElement?.dataset.gif;
      const addGifCursor = gifElement;
      const addHoverAnimation = (target.closest('a') || target.closest('button')) && !addGifCursor;

      // toggle hover animation
      if (addHoverAnimation && !hoverAnimation) {
        setHoverAnimation(true);
      }

      if (!addHoverAnimation && hoverAnimation) {
        setHoverAnimation(false);
      }
      // toggle gif cursor
      if (addGifCursor && !gifCursor) {
        setGifCursor(gifCursorData);
      }

      if (!addGifCursor && gifCursor) {
        setGifCursor(false);
      }

      requestAnimationFrame(render);
    };
    // Run custom animation function
    requestAnimationFrame(render);
    // Remove event listener on clean up
    // return () => {
    //   document.removeEventListener('mousemove', setMousePosition, false);
    // };
  }, [hoverAnimation, gifCursor]);

  // cursor class names
  const hoverClass = hoverAnimation ? 'hovering' : '';
  const gifClass = gifCursor ? 'gif-cursor' : '';

  return (
    <>
      <div className={`cursor ${hoverClass} ${gifClass}`}>
        {gifCursor && (
          <div className="cursor-gif">
            <Image image={{ url: gifCursor, alt: 'Say what' }} />
          </div>
        )}
      </div>
    </>
  );
};

export default Cursor;

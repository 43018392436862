import React from 'react';
import { Section, ThemeToggle, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const TextAndInlineImages = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { content, darkmode } = primary;

  // content checks
  const contentText = content?.text;
  const contentArr = contentText.split('[image]', 99);

  const theme = darkmode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section>
        <div className={styles.text}>
          {contentArr.map((string, index) => (
            <>
              <span>{string}</span>
              {items[index] && (
                <OnVisible className={styles.image} visibleClassName={styles.imageVisible}>
                  <Image image={items[index]?.image} />
                </OnVisible>
              )}
            </>
          ))}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default TextAndInlineImages;

// extracted by mini-css-extract-plugin
export var container = "styles-module--container--tNP79";
export var tagline = "styles-module--tagline--4KBGp";
export var title = "styles-module--title--f765V";
export var right = "styles-module--right--0iyMp";
export var awards = "styles-module--awards--3yvlZ";
export var award = "styles-module--award--B6q18";
export var awardVisible = "styles-module--awardVisible--VL1Dy";
export var badge = "styles-module--badge--3pHEj";
export var name = "styles-module--name--nE1VG";
export var awardText = "styles-module--awardText--E6xiF";
export var year = "styles-module--year--7q9hJ";
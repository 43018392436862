import React from 'react';
import { Button, Section, ThemeToggle, OnVisible } from 'components';
import './styles.scss';

const FooterInfo = ({ footerContent }) => {
  const {
    address,
    address_label: addressLabel,
    contact_details: contactDetails,
    contact_label: contactLabel,
    contact_subtitle: contactSubtitle,
    contact_title: contactTitle,
    cta_link: ctaLink,
    cta_text: ctaText,
    fun_fact: funFact,
  } = footerContent;

  const hasFunFact = funFact?.text;

  return (
    <ThemeToggle theme="light-mode">
      <Section className="footer-info">
        <div className="footer-info-grid">
          <div className="footer-info-contact-details">
            <div className="footer-info-address">
              <div className="footer-info-label" dangerouslySetInnerHTML={{ __html: addressLabel.html }} />
              <div className="footer-info-details" dangerouslySetInnerHTML={{ __html: address.html }} />
            </div>
            <div className="footer-info-contact">
              <div className="footer-info-label" dangerouslySetInnerHTML={{ __html: contactLabel.html }} />
              <div className="footer-info-details" dangerouslySetInnerHTML={{ __html: contactDetails.html }} />
            </div>
            {hasFunFact && <div className="footer-info-fun-fact" dangerouslySetInnerHTML={{ __html: funFact.html }} />}
          </div>
          <OnVisible className="footer-info-cta">
            <div className="footer-info-cta-title" dangerouslySetInnerHTML={{ __html: contactTitle.html }} />
            <div className="footer-info-cta-subtitle" dangerouslySetInnerHTML={{ __html: contactSubtitle.html }} />
            <Button className="footer-info-cta-button" to={ctaLink.url}>
              {ctaText.text}
            </Button>
          </OnVisible>
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default FooterInfo;

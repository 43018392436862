import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Section, ThemeToggle, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Awards = (props) => {
  const { data } = props;
  const { primary } = data;
  const { tagline, title, dark_mode: darkMode } = primary;

  const staticData = useStaticQuery(graphql`
    query {
      ...AwardsFragment
    }
  `);

  const {
    data: { prismicSettings },
  } = useMergePrismicPreviewData(staticData);

  const {
    data: { awards },
  } = prismicSettings;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className={styles.section} containerClassName={styles.container}>
        <div className={styles.left}>
          {taglineHtml && <div className={styles.tagline} dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        </div>
        <div className={styles.right}>
          <div className={styles.awards}>
            {awards.map((item) => {
              const { badge, certificate, company, award_title: award, year } = item;
              const companyText = company?.text;
              const awardText = award?.text;
              return (
                <OnVisible
                  key={`${companyText}-${awardText}-${year}`}
                  className={styles.award}
                  visibleClassName={styles.awardVisible}
                >
                  <div className={styles.badge}>
                    <Image image={badge} />
                  </div>
                  <div
                    className={`${styles.awardText} with-gif`}
                    data-gif={`${certificate?.url}&w=250`}
                    dangerouslySetInnerHTML={{ __html: awardText }}
                  />
                  <div className={styles.year} dangerouslySetInnerHTML={{ __html: year }} />
                </OnVisible>
              );
            })}
          </div>
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default Awards;

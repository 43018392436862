const generatePath = require('./generatePath');

module.exports = (doc) => {
  const { uid } = doc;

  if (doc.type === 'blog_post') {
    return `/blog${generatePath(uid)}`;
  }

  return generatePath(uid);
};

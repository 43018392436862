import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, Slice, FooterInfo, Testimonials, ThemeToggle } from 'components';

const Page = ({ location, data }) => {
  const { prismicPage, prismicSettings } = data;
  const { data: pageData } = prismicPage;
  const { data: settingsData } = prismicSettings || {};
  // pass to testimonials
  const { footer_content: footerArray } = settingsData || {};
  const footerContent = footerArray ? footerArray[0] : null;
  // pass to footer component
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    schema,
    service_page: servicePage,
    dark_mode: darkMode,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme} isPageWrapper>
      <Layout location={location} seo={seo}>
        {sliceData.map((slice, index) => {
          if (!slice) return null;
          return (
            <Slice
              key={slice.id}
              data={slice}
              location={location}
              servicePage={servicePage}
              isFirstSlice={index === 0}
            />
          );
        })}
        {footerContent && (
          <>
            <Testimonials testimonials={settingsData} />
            <FooterInfo footerContent={footerContent} />
          </>
        )}
      </Layout>
    </ThemeToggle>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        schema {
          text
        }
        service_page
        dark_mode
        body {
          ...CardGridPageFragement
          ...CaseStudiesSnapshotPageFragement
          ...ContactCtaPageFragement
          ...IntroHeaderPageFragement
          ...OneColImageCardPageFragement
          ...UspsPageFragement
          ...InfoImageColsPageFragment
          ...CardListPageFragment
          ...TwoColsImagePageFragment
          ...LinksColumnsPageFragment
          ...ContactFormPageFragment
          ...ClientLogosPageFragement
          ...InfoCardPageFragement
          ...MultiStepFormPageFragment
          ...PlainCarouselPageFragment
          ...TeamPageFragment
          ...AwardsPageFragment
          ...TextAndInlineImagesPageFragment
        }
      }
    }
    prismicSettings {
      data {
        tagline {
          text
          html
        }
        testimonial {
          author {
            text
            html
          }
          author_image {
            url
            alt
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 80, placeholder: BLURRED)
              }
            }
          }
          content {
            text
            html
          }
          position {
            text
            html
          }
        }
        title {
          text
          html
        }
        footer_content {
          address {
            text
            html
          }
          address_label {
            text
            html
          }
          contact_details {
            text
            html
          }
          contact_label {
            text
            html
          }
          contact_subtitle {
            text
            html
          }
          contact_title {
            text
            html
          }
          cta_link {
            url
          }
          cta_text {
            text
            html
          }
          fun_fact {
            text
            html
          }
        }
      }
    }
  }
`;

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

const Link = (props) => {
  const {
    children,
    activeClassName,
    className = '',
    to,
    target = '_blank',
    title = null,
    ariaLabel = null,
    tabIndex,
    onClick = () => {},
    onMouseOver = () => {},
    onMouseOut = () => {},
  } = props;

  const sharedProps = {
    className,
    title,
    ariaLabel,
    tabIndex,
    onClick,
    onMouseOver,
    onMouseOut,
  };

  if (!to) return <span {...sharedProps}>{children}</span>;

  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');

  const containsSiteUrl = process.env.SITE_URL && to && to.indexOf(process.env.SITE_URL) !== -1;

  if (isExternal && !containsSiteUrl) {
    return (
      <a href={to} target={target} rel="nofollow noopener noreferrer" {...sharedProps}>
        {children}
      </a>
    );
  }

  const withTrailingSlash = `${to}${to.endsWith('/') ? '' : '/'}`;
  const linkTo = containsSiteUrl ? withTrailingSlash.replace(containsSiteUrl, '') : withTrailingSlash;

  return (
    <GatsbyLink to={linkTo} activeClassName={activeClassName} {...sharedProps}>
      {children}
    </GatsbyLink>
  );
};

export default Link;

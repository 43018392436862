import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { ThemeToggle, Image, Section } from 'components';
import './styles.scss';

const ClientLogos = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, content, sub_title: subtitle, dark_mode: darkMode } = primary;

  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const subtitleHtml = subtitle?.html;
  // const hasIcon = icon?.localFile || icon?.url;
  const theme = darkMode ? 'dark-mode' : 'light-mode';

  const staticData = useStaticQuery(graphql`
    query {
      ...ClientLogosFragment
    }
  `);

  const { data: clientLogoData } = useMergePrismicPreviewData(staticData);

  const { prismicSettings } = clientLogoData;
  const {
    data: { client: items },
  } = prismicSettings;

  return (
    <ThemeToggle theme={theme}>
      <Section className="client-logos">
        {subtitleHtml && <div className="client-logos-subtitle" dangerouslySetInnerHTML={{ __html: subtitleHtml }} />}
        {titleHtml && <div className="client-logos-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        {contentHtml && <div className="client-logos-content" dangerouslySetInnerHTML={{ __html: contentHtml }} />}
        <div className="client-logos-container">
          {items.map((item, index) => {
            const { logo: image, classname: className, client_name: client } = item;
            return (
              <div className="client-logos-cell" key={image.url} style={{ transitionDelay: `${index * 0.2}s` }}>
                <Image image={image} alt={client?.text} className={className?.text} />
              </div>
            );
          })}
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default ClientLogos;

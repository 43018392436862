import React from 'react';
import { Section, ThemeToggle, Image, OnVisible } from 'components';
import * as styles from './styles.module.scss';

const Team = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const { tagline, title, dark_mode: darkMode } = primary;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className={styles.section} containerClassName={styles.container}>
        <div className={styles.left}>
          {taglineHtml && <div className={styles.tagline} dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
          {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
        </div>
        <div className={styles.right}>
          <div className={styles.team}>
            {items.map((card) => {
              const { name, position, image } = card;
              const nameHtml = name?.html;
              const nameText = name?.text;
              const positionHtml = position?.html;
              return (
                <OnVisible
                  key={nameText}
                  className={styles.teamMemberContainer}
                  visibleClassName={styles.teamMemberContainerVisible}
                >
                  <div className={styles.teamMember}>
                    <Image image={image} aspectRatio={157 / 210} />
                    <div className={styles.name} dangerouslySetInnerHTML={{ __html: nameHtml }} />
                    <div className={styles.position} dangerouslySetInnerHTML={{ __html: positionHtml }} />
                  </div>
                </OnVisible>
              );
            })}
          </div>
        </div>
      </Section>
    </ThemeToggle>
  );
};

export default Team;

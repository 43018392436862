module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Woolly Mammoth', // Default Site Title used for SEO & PWA
  description: 'Sydney UX and full stack development studio', // Default Site Decription used for SEO
  siteName: 'Woolly Mammoth', // Sitename for Facebook
  siteLanguage: 'en', // Language Tag on <html> element
  banner: '/open-graph.jpg', // Default OpenGraph image
  ogLanguage: 'en_US', // Facebook Language
  socialLinks: [
    'https://www.instagram.com/mammoth.tech',
    'https://www.facebook.com/Woolly-Mammoth-100497805258110/',
    'https://twitter.com/mammothdottech?lang=en',
    'https://www.linkedin.com/company/woollymammoth/',
  ], // Array of social links (facebook, insta, etc)
  // JSONLD / Manifest
  icon: 'src/images/maskable_icon.png', // Used for manifest favicon generation
  shortName: 'Woolly Mammoth', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Woolly Mammoth', // Author for schemaORGJSONLD
  themeColor: '#141414', // this colour is used for address/status bar/icons in running apps screen if no maskable option is available
  backgroundColor: '#141414', // colour displayed before the stylesheet loads

  twitter: '', // Twitter Username
  googleTagManagerId: 'GTM-5HDQVD8',
};

import React from 'react';
import { Image, Section, SmallButton, ThemeToggle } from 'components';
import './styles.scss';

const TwoColsImage = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, description, cta_text: ctaText, cta_link: ctaLink, image, dark_mode: darkMode } = primary;

  // content checks
  const titleHtml = title?.html;
  const descriptionHtml = description?.html;
  const hasCta = ctaText?.text && ctaLink?.url;

  const theme = darkMode ? 'dark-mode' : 'light-mode';

  return (
    <ThemeToggle theme={theme}>
      <Section className="two-cols-image">
        <div className="two-cols-image-text">
          {titleHtml && <div className="two-cols-image-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
          {descriptionHtml && (
            <div className="two-cols-image-description" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
          )}
          {hasCta && <SmallButton to={ctaLink.url}>{ctaText.text}</SmallButton>}
        </div>
        <Image className="two-cols-image-image" image={image} />
      </Section>
    </ThemeToggle>
  );
};

export default TwoColsImage;

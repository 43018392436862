// extracted by mini-css-extract-plugin
export var container = "styles-module--container--13lZy";
export var tagline = "styles-module--tagline--kwLiA";
export var title = "styles-module--title--3uh+k";
export var right = "styles-module--right--4Bctd";
export var team = "styles-module--team--cqStK";
export var teamMemberContainer = "styles-module--teamMemberContainer--76wz-";
export var teamMemberContainerVisible = "styles-module--teamMemberContainerVisible--ApD4h";
export var teamMember = "styles-module--teamMember--RhQx0";
export var name = "styles-module--name--+Ws1s";
export var position = "styles-module--position--qnnjr";
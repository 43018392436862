import React from 'react';
import { Link } from 'components';
import { siteName } from '../../../config/website';
import LogoSvg from './logo.inline.svg';
import LogoFullSvg from './logo-full.inline.svg';
import * as styles from './styles.module.scss';

const Logo = (props) => {
  const { className, fullLogo, dark } = props;

  return (
    <Link
      className={`${fullLogo ? styles.fullLogo : styles.logo} ${dark ? styles.dark : ''} ${className || ''}`}
      to="/"
      title={siteName}
    >
      {fullLogo ? <LogoFullSvg /> : <LogoSvg />}
    </Link>
  );
};

export default Logo;

import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useStaticQuery, graphql } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { Carousel, Icon, Section, SmallButton, ThemeToggle } from 'components';
import { InfoCard } from 'slices';
import './styles.scss';

const CaseStudiesSnapshot = (props) => {
  const { data } = props;
  const { primary: primaryData } = data;
  const { cta_link: to, cta_text: text, icon, tagline, title, dark_mode: darkMode } = primaryData;

  // content checks
  const titleHtml = title?.html;
  const taglineHtml = tagline?.html;
  const ctaText = text?.text;
  const ctaLink = to?.url;

  const [portalRef, setPortalRef] = useState(null);
  const arrowContainerRef = useRef();

  useEffect(() => {
    setPortalRef(arrowContainerRef?.current);
  }, [arrowContainerRef]);

  const theme = darkMode ? 'dark-mode' : 'light-mode';
  // card cardContent
  const cardQuery = graphql`
    query projectsPage {
      projectsPage: prismicPage(uid: { eq: "projects" }) {
        data {
          body {
            ...InfoCardPageFragement
          }
        }
      }
    }
  `;
  const staticData = useStaticQuery(cardQuery);
  const {
    data: { projectsPage },
  } = useMergePrismicPreviewData(staticData);
  // projectsPage
  const { data: projectsPageData } = projectsPage;
  const { body: unfilteredCardsArray } = projectsPageData;

  const cardsArray = unfilteredCardsArray
    .filter((card) => card?.primary?.homepage_order)
    .sort((a, b) => (a?.primary?.homepage_order >= b?.primary?.homepage_order ? 1 : -1));

  // Carousel settings
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 7000,
    speed: 300,
    focusOnSelect: false,
    centerMode: false,
    adaptiveHeight: true,
    nextArrow: <Arrow portalRef={portalRef} />,
    prevArrow: <Arrow portalRef={portalRef} />,
    appendDots: (children) => <Dots portalRef={portalRef}>{children}</Dots>,
  };

  return (
    <ThemeToggle theme={theme}>
      <Section className="cs-snapshot">
        {titleHtml && (
          <div className="cs-snapshot-header">
            <div>
              {icon && <Icon iconText={icon} />}
              {taglineHtml && <div className="cs-snapshot-tagline" dangerouslySetInnerHTML={{ __html: taglineHtml }} />}
              {titleHtml && <div className="cs-snapshot-title" dangerouslySetInnerHTML={{ __html: titleHtml }} />}
              {ctaText && ctaLink && (
                <SmallButton className="cs-snapshot-cta" to={ctaLink}>
                  {`– ${ctaText}`}
                </SmallButton>
              )}
            </div>
            <div className="cs-snapshot-arrows" ref={arrowContainerRef} />
          </div>
        )}
        <Carousel settings={settings}>
          {cardsArray.map((card) => {
            const { primary, items } = card;
            return <InfoCard key={primary?.title?.text} data={{ primary, items }} noWrapper isHome />;
          })}
        </Carousel>
      </Section>
    </ThemeToggle>
  );
};

const Arrow = (props) => {
  const { className, style, onClick, portalRef } = props;
  if (!portalRef) return null;
  return ReactDOM.createPortal(
    <button type="button" style={style} className={className} onClick={onClick} aria-label="Change Slide" />,
    portalRef
  );
};

const Dots = (props) => {
  const { children, portalRef } = props;
  if (!portalRef) return null;
  return ReactDOM.createPortal(<ul className="cs-snapshot-dots">{children}</ul>, portalRef);
};

export default CaseStudiesSnapshot;

import React, { useEffect, useState, useRef } from 'react';
import { OnVisible } from 'components';
import './styles.scss';

const Video = (props) => {
  const { id = 'video', src, type = 'video/mp4', placeholder, placeholderColor, className, loop } = props;
  const [loaded, setLoaded] = useState(false);
  const [visible, setVisible] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef?.current;
    if (video) {
      video.addEventListener('loadeddata', () => setLoaded(true), false);
      // Force video to show after 4 seconds incase
      // loadeddata listener does not fire
      setTimeout(() => {
        if (!loaded) setLoaded(true);
      }, 4000);
    }
  }, [videoRef]);

  useEffect(() => {
    const video = videoRef?.current;
    if (loaded && visible && video && video.paused) {
      video.play();
    }
  }, [visible, videoRef, loaded]);

  const onVisible = () => {
    setVisible(true);
  };

  return (
    <OnVisible
      className={`video-bg-container ${className || ''}`}
      style={{ background: placeholderColor || `url('${placeholder}') center no-repeat` }}
      onChange={onVisible}
    >
      {visible && (
        <video ref={videoRef} preload="true" autoPlay playsInline muted loop={loop} className="video" id={id}>
          <source src={src} type={type} />
          Sorry, your browser doesn&#39;t support embedded videos.
        </video>
      )}
    </OnVisible>
  );
};

export default Video;
